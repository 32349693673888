.markdown p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.markdown a {
  text-decoration: underline;
}

.markdown ul {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.markdown li {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}
